@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Roboto+Mono:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3 {
  letter-spacing: -0.4rem;
}

.scroll-prompt {
  position: absolute;
  bottom: 25%;
  left: 50%;
  z-index: 100;
  transition: 1s opacity ease-out;
}

.scroll-prompt .scroll-prompt-shape {
  position: relative;
  animation: slidedown 2.25s infinite;
}

.scroll-prompt .scroll-prompt-shape:before,
.scroll-prompt .scroll-prompt-shape:after {
  content: "";
  display: block;
  position: absolute;
  width: 3em;
  height: 0.5em;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 0 0.25em 0.25em 0;
  transform-origin: bottom left;
}

.scroll-prompt .scroll-prompt-shape:before {
  transform: scaleX(-1) rotate(-45deg);
}

.scroll-prompt .scroll-prompt-shape:after {
  transform: rotate(-45deg);
}

@keyframes slidedown {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
    transform: translate(0, 80px);
  }
  100% {
    opacity: 0;
    transform: translate(0, 80px);
  }
}


.spacer {
  aspect-ratio: 960/200;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.first-spacer {
  aspect-ratio: 960/50;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
